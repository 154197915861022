import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CollectionList from '@browse/components/CollectionList'
import AssetList from 'components/AssetList'

import useApi from 'hooks/useApi'

import './styles.css'

const BrowseScreen = () => {

	const { collectionId } = useParams()

	const { 
		loading: collectionsLoading, 
		data: collections,
		refresh: refreshCollections
	} = useApi('/collections?parent_id=' + (collectionId ?? 0))

	const { 
		loading: assetsLoading, 
		data: assets,
		refresh: refreshAssets
	} = useApi('/assets?collection_id=' + (collectionId ?? 0))



	useEffect(() => {

		refreshCollections()
		refreshAssets()

	}, [collectionId])


	
	return (
		<>
			
			<CollectionList 
				loading={collectionsLoading} 
				items={collections} 
				style={{marginTop: '30px'}}
			/>

			{ collections.length > 0 && assets.length > 0 &&
				<div className="BrowseScreen__list__divider" style={{marginTop: '30px'}}/>
			}

			<AssetList 
				loading={assetsLoading} 
				items={assets} 
				style={{marginTop: '30px'}}
			/>


		</>
	)
}

export default BrowseScreen