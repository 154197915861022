// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import ListScreen from "./screens/list"


const BrowseRoutes = () => 
{
	return (
		<Routes>
			<Route path=":collectionId" element={<ListScreen />}/>

			
			<Route path="*" element={<ListScreen />}/>

		</Routes>
	)
}

export default BrowseRoutes;