// Packages
import { useState, useEffect, useCallback, useRef } from 'react'
import axios from 'axios'


// Other
import Config from 'config'


const useApi = (url, autoGet = true) => {

	const [ data, setData ] = useState([])
	const [ meta, setMeta ] = useState([])
	const [ loading, setLoading ] = useState(autoGet)
	const [ errors, setErrors ] = useState({})
    const [ errorCode, setErrorCode] = useState()
	const [ refreshData, setRefreshData ] = useState(false)



    const get = async () => {
        await request(url, 'get')
    }

    const getRef = useRef(get);
    getRef.current = get;



    /**
     * Auto-Getter
     */
	useEffect(() =>
	{
        const getAuto = async () => {
            await getRef.current()
        }

        if ( autoGet )
        {
             getAuto()
        }

	}, [autoGet, refreshData])


    /**
     * Redirect to Oakora Auth
     */
    const redirectToAuth = () => {
        localStorage.setItem(Config.RETURN_NAME, window.location.href)

        let returnDomain = window.location.protocol + '//' + window.location.hostname;

        if ( window.location.port )
        {
            returnDomain += ':' + window.location.port
        }


        window.location = Config.AUTH_URL + '/auth/app?return=' + returnDomain 
    }


    /**
     * Return access token
     */
    const getAccessToken = () => {

        if ( localStorage.getItem(Config.TOKEN_NAME) )
        {
            return localStorage.getItem(Config.TOKEN_NAME)
        }
        else
        {
            // No access token. go to auth
            redirectToAuth()
        }
    }


    


	const put = async (postData) => {
        await request(url, 'put', postData)
    }


    const post = async (postData) => {
        await request(url, 'post', postData)
    }


    const destroy = async () => {
        await request(url, 'delete')
    }


    const refresh = useCallback(() => {
        setRefreshData(!refreshData)
    }, [refreshData])


    const request = async (url, method, requestBody) => {

        setLoading(true)

        try 
        {
            const response = await axios.request({
                baseURL: Config.API_URL,
                url,
                method,
                responseType: 'json',
                body: requestBody,
                headers: {
                    Authorization: 'Bearer ' + getAccessToken()
                }
            })
                
            setData(response.data.data)
            setMeta(response.data.meta ?? null)
        } 
        catch (error)
        {
            setErrors(error.response.data.errors)
            setErrorCode(error.response.status)

            if ( error.response.status === 401 )
            {
                redirectToAuth()
            }
        }
        finally {
            setLoading(false)
        }
    }


	return { 
        data,
        meta,
		loading, 
		isError: errors, 
        errors,
        errorCode,
        get,
		put, 
		post,
        destroy,
        refresh
	}
}

export default useApi