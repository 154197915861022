// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import ViewScreen from "./screens/view"


const ResourceRoutes = () => 
{
	return (
		<Routes>
			<Route path="/:assetId" element={<ViewScreen />}/>
		</Routes>
	)
}

export default ResourceRoutes;