import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import Config from 'config'

const TokenScreen = () => {

	const [searchParams] = useSearchParams()

	useEffect(() => {

		const token = searchParams.get('_token')
		const returnUrl = localStorage.getItem(Config.RETURN_NAME)

		if ( token && returnUrl )
		{
			localStorage.setItem(Config.TOKEN_NAME, token)
			localStorage.removeItem(Config.RETURN_NAME)

			window.location = returnUrl
		}

	}, [searchParams])


	return (<>x</>)
}

export default TokenScreen