/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
	STAGE: process.env.REACT_APP_STAGE,

	AUTH_URL: "https://auth.oakora.com",
	AUTH_API_URL: "https://api.auth.oakora.com",

	API_URL: "https://api.library.oakora.com",


	// localStorage var names
	TOKEN_NAME: 'oakora-library-access-token',
	RETURN_NAME: 'oakora-library-return'
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

	/*
	Production Environment Configuration
	*/
	production: {
		
	},
	

	/*
	Staging Environment Configuration
	*/
	staging: {
	},


	/*
	Development Environment Configuration
	*/
	development: {
	},


	/*
	Local Environment Configuration
	*/
	local: {
		AUTH_API_URL: "http://api.auth.oakora.local",
		AUTH_URL: "http://localhost:3000",
		API_URL: "http://api.library.oakora.local"
	}

}

const config = {
	...generalConfig,
	...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config