
import Container from 'components/Container'

import './styles.css'

import { useAppData } from 'context/AppData'

import Config from 'config'


const TenantBar = props => {

	const { user, tenant, authSettings, apps } = useAppData()

	if ( authSettings.tenantbar_enabled !== "true" )
	{
		return (<></>)
	}

	return (
		<div className="TenantBar" style={{
			backgroundColor: authSettings.tenantbar_background_color
		}}>

			<Container>

				<div className="TenantBar__container">

					<div className="TenantBar__logo_and_apps">
						<div className="TenantBar__logo">
							<img src={authSettings.tenantbar_logo_url} alt={tenant.name} className="TenantBar__logo__img"/>
						</div>

						<div className="TenantBar__apps">
							<ul className="TenantBar__apps__list">
								{apps.map((app) => {

									if ( app.enabled === false )
									{
										return (<></>)
									}

									return (
										<li key={app.tenant.domain} className="TenantBar__apps__list__item">
											<a href={`https://${app.tenant.domain}`} className="TenantBar__apps__list__item__link">{app.tenant.name}</a>
										</li>
									)
								})}
							</ul>
						</div>
					</div>

					<div className="TenantBar__user" onClick={() => {
						document.getElementById('TenantBar__user__dialog').classList.toggle('TenantBar__user__dialog--active')
					}}>

						<div className="TenantBar__user__name">
							{user.firstname} {user.lastname}
						</div>

						<div className="TenantBar__user__icon"/>

						<div id="TenantBar__user__dialog" className="TenantBar__user__dialog">
							<a href={Config.AUTH_URL} className="TenantBar__user__dialog__link">Your Profile</a>
						</div>

					</div>
				</div>

			</Container>

		</div>
	)
}

export default TenantBar