import Header from 'components/Header'

import Container from 'components/Container'

import TenantBar from 'components/TenantBar'

import './styles.css'


const DefaultLayout = props => {


	return (
		<div className="DefaultLayout">

			<TenantBar/>

			<Container>

				<Header style={{marginTop: '80px'}}/>
			
				{props.children}

			</Container>

		</div>
	)
}

export default DefaultLayout