

import './styles.css'

const Heading = props => {

	const { 
		title = 'No title set', 
		size = 1, 
		style = {}
	} = props

	return (
		<div className={`Heading Heading--size-${size}`} style={style}>
			{title}
		</div>
	)
}

export default Heading