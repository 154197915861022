import { useParams } from 'react-router-dom'
import Heading from 'components/Heading'
import Loading from 'components/Loading'
import useApi from 'hooks/useApi'


const ResourceViewScreen = () => {

	const { assetId } = useParams()

	const { loading, data } = useApi('/assets/' + assetId)

	if ( loading )
	{
		return <Loading/>
	}
	
	return (
		<>
			<Heading title={data.name} style={{marginTop: '30px'}}/>
			


		</>
	)
}

export default ResourceViewScreen