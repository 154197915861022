// Components
import Routes from './routes'


// Other
import 'assets/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'

function App() {

	return (
		<Routes/>
	)
}

export default App
