import { Link } from 'react-router-dom'
import Navigation from 'components/Navigation'
import FavIcon from 'components/Icons/Heart'
import { useAppData } from 'context/AppData'

import './styles.css'


const Header = props => {

	const { style } = props

	const { appSettings } = useAppData()

	return (
		<div className="Header" style={style}>

			<Link to="/">
				<div className="Header__logo" style={{
					backgroundImage: `url(${appSettings.logo_url})`
				}}/>
			</Link>

			<div className="Header__navigation">

				<Navigation items={[
					{ label: 'Home', url: '/'},
					{ label: 'Browse Resources', url: '/browse'},
					{ label: 'Recent Updates', url: '/recent'},
					{ icon: <FavIcon/>, url: '/favourites'},
				]}/>

			</div>

		</div>
	)
}

export default Header