

const CollectionIcon = props => {

	const { color, size } = props

	return (
	
		<svg height={size} viewBox="0 0 512 512" width={size} xmlns="http://www.w3.org/2000/svg">
		<g>
			<g>
				<rect fill={color} height="234.43" rx="41.38" width="234.43" x="277.57"/>
				<rect fill={color} height="234.43" rx="41.38" width="234.43"/>
				<rect fill={color} height="234.43" rx="41.38" width="234.43" x="277.57" y="277.57"/>
				<rect fill={color} height="234.43" rx="41.38" width="234.43" y="277.57"/>
			</g>
		</g>
		</svg>	
	)
}

CollectionIcon.defaultProps = {
	colour: '#000000',
	size: '20px'
}

export default CollectionIcon