import { createContext, useEffect, useContext } from 'react'

import Loading from 'components/Loading'

import useApi from 'hooks/useApi'
import Config from 'config'


const AppDataContext = createContext()



export const AppDataProvider = ({ children }) => {

    const { data, loading } = useApi(Config.API_URL + '/appdata')


    useEffect(() => {

        document.title = data?.app_settings?.product_name ?? 'Digital Library'

    }, [data])


    return (
        <AppDataContext.Provider value={{
            user: data.user,
            tenant: data.tenant,
            apps: data.apps,
            authSettings: data.auth_settings,
            appSettings: data.app_settings
        }}>

            { loading &&
                <Loading fullscreen={true}/>
            }

            { ! loading && data.user &&
                <>{children}</>
            }

        </AppDataContext.Provider>
    )
}


export const useAppData = () => {
    return useContext(AppDataContext)
}
