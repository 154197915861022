
import './styles.css'

const Loading = props => {

	const { fullscreen, size } = props


	return (
		<div className={`Loading Loading--fullscreen-${fullscreen} Loading--size-${size}`}>

			

		</div>
	)
}

export default Loading