
import './styles.css'

const Hamburger = props => {

	return (
		<div className="Hamburger">
			<div className="Hamburger__line Hamburger__line--1"/>
			<div className="Hamburger__line Hamburger__line--2"/>
			<div className="Hamburger__line Hamburger__line--3"/>
		</div>
	)
}

export default Hamburger