// Packages
import { Routes, Route } from "react-router-dom"

// Screens
import HomeScreen from "./screens/home"


const HomeRoutes = () => 
{
	return (
		<Routes>
			<Route path="/" exact element={<HomeScreen />}/>
		</Routes>
	)
}

export default HomeRoutes;