
import './styles.css'

const HomeSearch = props => {

	const { style } = props

	return (
		<div className="HomeSearch" style={style}>

			<input placeholder="What are you looking for?" className="HomeSearch__input"/>

		</div>
	)
}

export default HomeSearch