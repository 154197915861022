import Item from '@home/components/TileListItem'
import './styles.css'

import { useAppData } from 'context/AppData'

const TileList = props => {

	const { style, tiles = [], loading } = props

	const appData = useAppData()

	// reorder tiles into position numbers
	const tilesByPosition = {}

	for ( let i=0; i<tiles.length; i++ )
	{
		tilesByPosition[tiles[i].position] = tiles[i]
	}

	const rows = appData.appSettings.home_grid_layout.split(/,/)

	let tileIndex = 1

	return (
		<>
		{rows.map((row, index) => {

			const cols = row.split(/-/)

			style.gridTemplateColumns = ''

			for ( var i = 0; i < cols.length; i++ )
			{
				style.gridTemplateColumns += cols[i] + 'fr '
			}

			return (

				<div key={index} className="TileList" style={{...style}}>

				{ loading &&
					<>
					{cols.map((col, index) => (
						<Item key={index}/>
					))}
					</>
				}

				{ ! loading &&
					<>
					{cols.map((col, index) => (
						<Item key={index} {...tilesByPosition[tileIndex++] ?? null}/>
					))}
					</>
				}

				</div>
			)

			
		})}
		</>
	)
}

export default TileList