import { Link } from 'react-router-dom'
import Hamburger from 'components/Hamburger'

import './styles.css'

const Navigation = props => {

	const { items } = props


	return (
		<div className="Navigation">

			<div className="Navigation__hamburger">
				<Hamburger/>
			</div>

			<ul className="Navigation__list">

			{items.map((item) => {

				return (
					<li key={item.url} className="Navigation__list__item">

						<Link to={item.url} className="Navigation__list__item__link">

							{item.label}

							{item.icon}

						</Link>

					</li>
				)

			})}

			</ul>

		</div>
	)
}

export default Navigation