import Item from '@browse/components/CollectionListItem'
import './styles.css'

const CollectionList = props => {

	const { items, loading, style } = props

	return (
		<div className="CollectionList" style={style}>

			{ loading &&
				<>
					<Item/>
					<Item/>
				</>
			}

			{ ! loading &&
				<>
				{items.map((item) => {
					return <Item key={item.id} {...item}/>
				})}
				</>
			}
			
		</div>
	)
}

CollectionList.defaultProps = {
	items: [],
	loading: false,
	style: {}
}

export default CollectionList