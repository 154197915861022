import { Routes, Route } from "react-router-dom"


import HomeRoutes from '@home/routes'
import BrowseRoutes from '@browse/routes'
import ResourceRoutes from '@resources/routes'
import AuthRoutes from '@auth/routes'

import Error404Screen from '@errors/screens/404'

import { AppDataProvider } from 'context/AppData';


import Layout from 'layouts/Default'



const PrivateRoutes = () => (
    <AppDataProvider>
        <Layout>
        <Routes>
            <Route path="/browse/*" element={<BrowseRoutes/>} />
            <Route path="/resources/*" element={<ResourceRoutes/>} />
            <Route path="/" exact element={<HomeRoutes/>} />

            <Route path="*" element={<Error404Screen/>} />
        </Routes>
        </Layout>
    </AppDataProvider>
)




const PublicRoutes = () => (
        <Routes>
            <Route path="/auth/*" element={<AuthRoutes />} />
            <Route path="*" element={<PrivateRoutes />} />
        </Routes>
)





export default PublicRoutes
