
import './styles.css'

const Container = props => {

	const { className } = props

	return (
		<div className={`Container ${className}`}>
			{props.children}
		</div>
	)
}

export default Container