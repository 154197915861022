import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import './styles.css'

const AssetListItem = props => {

	const { id, name, image_url } = props

	return (
		<Link to={`/resources/${id}`}>
		<div className="CollectionListItem">

			<div className="CollectionListItem__header">
				<div className="CollectionListItem__header__image" style={{
					backgroundImage: `url(${image_url})`
				}}>

				</div>
				<div className="CollectionListItem__header__icon">
				</div>
			</div>

			<div className="CollectionListItem__name">
				{name}
			</div>
			
		</div>
		</Link>
	)
}

export default AssetListItem