

const HeartIcon = props => {
	return (
		<svg width="20px" height="16.4px" viewBox="0 0 20 16.4" version="1.1">
		    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <g transform="translate(-1400, -134)" fill="#2A3042" fillRule="nonzero">
		            <g transform="translate(1400, 131)">
		                <g transform="translate(0, 3)">
		                    <path d="M14.5599829,0.000164652225 C17.5583214,0.0147861823 19.9854021,2.44574337 20,5.4489852 C20,10.9511794 10.0000255,16.4 10.0000255,16.4 C10.0000255,16.4 0,10.8710678 0,5.4489852 C0,2.43971071 2.43555356,0.000164652225 5.44001715,0.000164652225 L5.44001715,0.000164652225 C7.27914924,-0.0142012567 8.99853766,0.912323183 10.0000255,2.45745082 C11.008353,0.919838445 12.7233008,-0.00428315588 14.5599829,0.000164652225 Z"></path>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	)
}

export default HeartIcon