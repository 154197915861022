import Skeleton from 'react-loading-skeleton'
import { useNavigate, Link } from 'react-router-dom'

import { useAppData } from 'context/AppData'

import './styles.css'

const TileListItem = props => {

	const { name, image_url, link_target, link_value } = props

	const navigate = useNavigate()
	const appData = useAppData()

	const getUrl = () => {

		if ( link_target === 'url' )
		{
			return link_value
		}
		else if ( link_target === 'asset' )
		{
			return '/resources/' + link_value
		}
		else if ( link_target === 'collection' )
		{
			return '/browse/' + link_value
		}
		else 
		{
			return window.location.href
		}
	}

	return (

		<Link to={getUrl()}>

		<div className="TileListItem">

			{ ! name &&
				<>
				<Skeleton height="230px"/>
				<Skeleton height="20px" width="75%" style={{marginTop: '5px'}}/>
				</>
			}

			{ name &&
				<>

				<div className="TileListItem__image" style={{
					height: appData.appSettings.home_grid_image_height
				}}>
					<div className="TileListItem__image__background" style={{
						backgroundImage: `url(${image_url})`
					}}/>
				</div>

				{ appData.appSettings.home_grid_labels === 'true' &&
					<div className="TileListItem__label">
						{name}
					</div>
				}

				</>
			}

		</div>

		</Link>
	)
}

export default TileListItem