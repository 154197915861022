
import './styles.css'

const Error404 = () => {

    return (

            <div className="Error404">

                <div className="Error404__image"/>

                <div className="Error404__heading">
                    Not Found
                </div>

                <div className="Error404__description">
                    The page or resource you have requested was not available.
                </div>

            </div>

    )
}

export default Error404
