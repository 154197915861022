import Item from 'components/AssetListItem'
import './styles.css'

const AssetList = props => {

	const { items, loading, style } = props

	return (
		<div className="AssetList" style={style}>

			{ loading &&
				<>
					<Item/>
					<Item/>
				</>
			}

			{ ! loading &&
				<>
				{items.map((item) => {
					return <Item key={item.id} {...item}/>
				})}
				</>
			}
			
		</div>
	)
}

AssetList.defaultProps = {
	items: [],
	loading: false,
	style: {}
}

export default AssetList