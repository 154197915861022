
import TileList from '@home/components/TileList'
import Search from '@home/components/Search'
import Heading from 'components/Heading'
import useApi from 'hooks/useApi'

const DashboardViewScreen = () => {

	const { data: tiles } = useApi('/tiles')
	
	return (
		<>
			<Search style={{marginTop: '30px'}}/>
			
			<TileList tiles={tiles} style={{marginTop: '30px'}}/>		

			<Heading 
				title="Trending Resources"
				size="1"
				style={{marginTop: '50px'}}
			/>



			<Heading 
				title="Recent Updates"
				size="1"
				style={{marginTop: '50px'}}
			/>

		</>
	)
}

export default DashboardViewScreen